.menu-wrapper {
  -webkit-overflow-scrolling: touch;
  /* scroll mementum */
  overflow: auto !important;
}
.menu-item {
  padding: 0 5px;
  margin: 5px 3px;
  user-select: none;
  cursor: pointer;
  border: none;
  color: aliceblue;
}
.menu-item-wrapper.active {
  border-bottom: 1px solid white;
}
.menu-item.active {
  border-top: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  color: white;
  cursor: pointer;
}
