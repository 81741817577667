/* prettier-ignore */
/* @import url('https://fonts.googleapis.com/css?family=Sniglet'); */

body {
  background-color: #ececec;
  margin: 0;
  padding: 0;
  font-family: "Sniglet", cursive !important;
  /* font-weight: 400 !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Sniglet-auto", cursive !important;
  font-display: auto;
  src: local("Sniglet"),
    url(https://fonts.gstatic.com/s/sniglet/v9/rC7kKhY-eUDY-ucISTIf5PesZW2xOQ-xsNqO47m55DA.woff2)
      format("woff2");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
